import React, { Component } from 'react';
import banner1 from '../img/contact/mainContact.png'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import banneryellow from '../img/shape/BG-1.png'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
class Contact extends Component {
   constructor(props) {
      super(props);
      this.state = {
          errorlastname: '',
          errorfirstname: '',
          errorphone: '',
          erroremail: '',
          errorgraduation_date: '',
          errorcity: '',
          success:''
      };
      this.handleSubmit = this.handleSubmit.bind(this);
    }
  handleSubmit(event) {
      event.preventDefault();
      const target = event.target;
      this.setState({errorlastname: ''});
      this.setState({errorfirstname: ''});
      this.setState({errorphone: ''});
      this.setState({erroremail: ''});
      this.setState({errorgraduation_date: ''});
      this.setState({errorcity: ''});

      if(target.lastname.value ==''){
          return this.setState({errorlastname: 'nom est obligatoire'});
        }
        if(target.firstname.value ==''){
          return this.setState({errorfirstname: 'prénom est obligatoire'});
        }
        if(target.phone.value ==''){
          return this.setState({errorphone: 'téléphone est obligatoire'});
        }
        if(target.email.value ==''){
          return this.setState({erroremail: 'email est obligatoire'});
        }
        if(target.graduation_date.value ==''){
          return this.setState({errorgraduation_date: "Année est obligatoire"});
        }
        if(target.city.value ==''){
          return this.setState({errorcity: 'Ville est obligatoire'});
        }
        this.setState({success: 'Merci de nous avoir contacté, nous avons bien reçu vos informations'});
        target.reset();
        window && window.dataLayer && window.dataLayer.push({
          'event':'form_lead_submit',
          'event_id': Math.random().toString(36).substr(2, 9) + '' + Math.random().toString(36).substr(2, 9) +'' + Math.random().toString(36).substr(2, 9),
      });
    }
    render() {

        return (
            <section className="contact__area pt-50 pb-0">
            <div className="container">
               <div className="row align-items-end">
                  <div className="col-xxl-7 col-xl-7 col-lg-6 pb-30">
                     <div className="contact__wrapper">
                        <div className="section__title-wrapper mb-40">
                           <h2 className="section__title">Candidats <span className="yellow-bg yellow-bg-big">Bacheliers<img src={banneryellow} alt="img not found"/></span></h2>
                           <p>Découvrez le programme en plus de détail</p>
                        </div>
                        <div className="contact__form">
                        {this.state.success
                                ? <div className='text-success fs-4 px-5'> {this.state.success} </div>
                                :
                                ''
                            }
                           <form method="POST" onSubmit={this.handleSubmit}>
                              <input type="text" name="formation" className='d-hidden' value="AERO" />
                              <div className="row">
                                 <div className="col-xxl-6 col-xl-6 col-md-6 mt-2">
                                    <TextField id="outlined-basic" label="Nom"  name="lastname" variant="outlined" fullWidth />
                                    {this.state.errorlastname
                                         ? <span className='text-danger'> {this.state.errorlastname} </span>
                                        : ''
                                    }
                                 </div>
                                 <div className="col-xxl-6 col-xl-6 col-md-6 mt-2" >
                                 <TextField id="outlined-basic" label="Prénom"  name="firstname" variant="outlined" fullWidth />
                                 {this.state.errorfirstname
                                         ? <span className='text-danger'> {this.state.errorfirstname} </span>
                                        : ''
                                    }
                                 </div>
                                 <div className="col-xxl-12 mt-2">
                                 <TextField id="outlined-basic" label="Télephone"  name="phone" variant="outlined" fullWidth />
                                 {this.state.errorphone
                                         ? <span className='text-danger'> {this.state.errorphone} </span>
                                        : ''
                                    }
                                 </div>
                                 <div className="col-xxl-12 mt-2">
                                 <TextField id="outlined-basic" label="Email" name="email" variant="outlined" fullWidth />
                                 {this.state.erroremail
                                         ? <span className='text-danger'> {this.state.erroremail} </span>
                                        : ''
                                    }
                                 </div>
                                 <div className='col-md-6 mt-2'>
                                          <Autocomplete
                                            disablePortal
                                            className='selectDate'
                                            id="combo-box-demo"
                                            options={annee}
                                            renderInput={(params) => <TextField {...params} label="Année d'obtention BAC" name="graduation_date" />}
                                          />
                                            {this.state.errorgraduation_date
                                         ? <span className='text-danger'> {this.state.errorgraduation_date} </span>
                                        : ''
                                    }
                                 </div>
                                 <div className='col-md-6 mt-2'>
                                          <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            className='selectVille'
                                            options={villes}
                                            renderInput={(params) => <TextField {...params} label="Ville"  name="city" />}
                                          />
                                           {this.state.errorcity
                                         ? <span className='text-danger'> {this.state.errorcity} </span>
                                        : ''
                                    }
                                 </div>
                                 <div className='col-md-12 mt-2'>
                                <FormControlLabel control={<Checkbox  />} label="J’ai lu et j’accepte mentions légales"/>
                                </div>
                                 <div className="col-xxl-12 mt-3">
                                    <button className="g-btn  w-80 mt-2 fs-4" type='submit'> Je m’inscris</button>
                                 </div>
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1">
                  <div className="about__thumb-wrapper">
                        <div className="about__thumb mt-30">
                           <img src={banner1} alt="img not found" />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section> 
        );
    }
}
const annee = [
   "Année d'obtention BAC",
   "2021/2022",
   "2020/2021 ",
   "2019/2020 ",
   "2018/2019 ",
   "2017/2018 ",
   "Autre",
];
const villes=[
"Agadir",
"Ahfir",
"Alhoceima",
"Asilah",
"Azilal",
"Azrou",
"Beni Mellal",
"Berkane",
"Berrechid",
"Boujaad",
"Boujdour",
"Casablanca",
"Chefchaouen",
"Dakhla",
"El_Jadida",
"Erfoud",
"Er_rachidia",
"Essaouira",
"Fes",
"Figuig",
"Fquih ben saleh",
"Guelmim",
"Ifrane",
"Jerrada",
"Kasbat_tadla",
"Kenitra",
" Khemisset",
"Khenifra",
"Khouribga",
"Ksar el kbir",
"Laayoune",
"Lagouira",
"Larache",
"Marrakech",
"Meknes",
"Merzouga",
"Midelt",
"Mohammedia",
"Moulay Bousselham",
"Nador",
"Oualidia",
"Ouarzazate",
"Ouedzem",
"Ouazzane",
"Oujda",
"Oulmes",
"Rabat",
"Rissani",
"Safi",
"Salé",
"Sefrou",
"Settat",
"Sidiifni",
"Sidi kacem",
"Sidi slimane",
"Tanger",
"Tantan",
"Taroudant",
"Tata",
"Taza",
" Temara",
"Tétouan",
"Tiflet",
"Tineghir",
"Tiznit",
"Zagora",
"Autres",
]

export default Contact;