import React, { Component } from 'react';
import banneryellow from '../img/shape/BG-1.png'
import DirecteurImage from '../img/Directeur/Directeur-auto.jpg'
import Qoute from '../img/icon/icone-doyen.png'


class Directeur extends Component {

    render() {

        return (
        <section className="why__area pt-50 mb-40">
            <div className="container">
               <div className="row align-items-center justify-content-between">
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8">
                     <div className="why__content mt-40">
                        <div className="section__title-wrapper pr-0 mb-30">
                           <h2 className="section__title">MOT <span className="yellow-bg yellow-bg-big"> DU DIRECTEUR<img src={banneryellow} alt="img not found" /></span></h2>
                              <img src={Qoute} />
                           <p className='pt-15'>
                           En accompagnant le développement du secteur des énergies renouvelables du Maroc, l’école Supérieure de l’Ingénierie de l’énergie s’inscrit dans la démarche de formation des spécialistes de haut niveau indispensable à la réalisation d’objectifs ambitieux.
Unique en son genre au Maroc, le cycle de formation d’ingénieurs hautement qualifiés et performants intègre, sur un cursus de 5 années d’études et de façon complémentaire, les approches théoriques et appliquées.
                           </p>
                           <p>
                           M. KHALID BOUZIANE
                           <span className='d-block'>Directeur de l'école supérieure d'ingénierie de l'énergie</span>
                           </p>
                        </div>
                        <div className="why__btn">
                           <a href="/contact"><a className="g-btn e-btn-3 mr-30">Rejoignez-nous</a></a>
                        </div>
                     </div>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
                     <div className="why__thumb">
                        <img src={DirecteurImage} alt="img not found" className='w-100' />
                     </div>
                  </div>
               </div>
            </div>
         </section>
        );
    }
}

export default Directeur;