import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import logo from '../img/logo/logo-2.png'

const Footer = () => {

    return (
        <footer>
            <div className="footer__area footer-bg">
           
               <div className="footer__bottom">
            <div className="container">
                <div className="row justify-content-between">
                <div className="col-xl-5 col-md-5 justify-content-center d-flex align-items-center">
                    <div className="footer__copyright">
                        <p>Copyright 2022 <span className='mx-2'>- Mentions légales -</span> <a href="https://www.uir.ac.ma">www.uir.ac.ma</a></p>
                    </div>
                </div>
                <div className="col-xl-7 col-md-7">
                <div className="footer__social text-end">
                                    <ul>
                                       <li>
                                           <a href="#">
                                           <FacebookIcon />
                                           </a>
                                        </li>
                                       <li>
                                           <a href="#" className="in">
                                           <InstagramIcon />
                                           </a>
                                        </li>
                                       <li>
                                           <a href="#" className="pin">
                                           <YouTubeIcon />
                                           </a>
                                        </li>
                                       <li><a href="#" className="aedin">
                                            <LinkedInIcon />
                                           </a>
                                        </li>
                                    </ul>
                                 </div>    
               </div>
                </div>
            </div>
        </div>
            </div>
         </footer>
    );
}

export default Footer;